<template>
  <nav class="menu">
    <section>
      <div class="container">
        <div class="exul">
          <!-- <router-link to="/dashboard" class="nav-item nav-item--yellow">
            <a>Dashboard</a>
          </router-link> -->


          <div class="spacer" />
          <router-link to="/formularios" class="nav-item nav-item--orange formDesplegable">
            <a class="white-space: nowrap">Formularios</a>
            <div v-if="showMenu" class="menu-parcial" @mouseleave="handleMenuFalse">
              <template v-for="(starredFrom, index) in starredFormsForm" :key="index">
                <router-link :to="{ name: starredFrom }" class="nav-item" style="margin-top: 0">
                  <a>{{ starredFrom.split('-').join(' ') }}</a>
                </router-link>
              </template>
            </div>
          </router-link>

          <router-link v-if="hasFeriasPermission || hasAdminPermission" to="/ferias" class="nav-item nav-item--orange formDesplegable">
            <a class="white-space: nowrap">Ferias</a>
            <!-- <div class="menu-parcial" v-if="showMenu" v-on:mouseleave="handleMenuFalse">
              <template v-for="(starredFrom, index) in starredFormsFeria" :key="index">
                <router-link :to="{ name: starredFrom }" class="nav-item" style="margin-top: 0">
                  <a>{{ starredFrom.split('-').join(' ') }}</a>
                </router-link>
              </template>
            </div> -->
          </router-link>
        </div>
      </div>
    </section>
  </nav>

  <HandleReporteDate v-if="renderByRoute" />
  <Separator v-if="renderByRoute" />
</template>
<script>
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import HandleReporteDate from '@/components/HandleReporteDate.vue'
import Separator from '@/components/Separator.vue'

export default {
  name: 'MenuMain',
  components: {
    HandleReporteDate,
    Separator,
  },
  setup() {
    const route = useRoute()
    const store = useStore()
    const showMenu = ref(false)

    const testForms = ['Contactos', 'Llamados', 'Simulaciones', 'Prestamo-Computadores']

    // Computed
    const renderByRoute = computed(() => {
      const rutasPermitidas = ['Dashboard', 'Conversiones', 'Tráfico', 'Campañas', 'Demográficos']
      return rutasPermitidas.includes(route.name)
    })

    const starredFormsForm = computed(() => store.state.starredForms['form'])
    const starredFormsFeria = computed(() => store.state.starredForms['feria'])

    const hasFeriasPermission = computed(() => {
      return store.getters.getUserClaims?.['isFerias']
    })

    const hasAdminPermission = computed(() => {
      return store.getters.getUserClaims?.['isAdmin']
    })

    // Methods
    const handleMenuTrue = (e) => {
      if (e) e.preventDefault()
      showMenu.value = true
    }
    const handleMenuFalse = (e) => {
      if (e) e.preventDefault()
      showMenu.value = false
    }

    return {
      showMenu,
      handleMenuTrue,
      handleMenuFalse,
      renderByRoute,
      testForms,
      starredFormsForm,
      starredFormsFeria,
      hasFeriasPermission,
      hasAdminPermission,
    }
  },
}
</script>
<style lang="scss" scoped>
button {
  background-color: transparent;
  border: none;
  padding: 0;
}

section {
  background: #fea82b;
}

nav {
  margin-bottom: 1rem;
}

nav.menu {
  padding-top: 1.75rem;

  .exul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    font-weight: 500;

    .nav-item {
      display: block;
      margin-top: 2rem;
      padding: 1rem 2rem;

      &--orange {
        background: #ff7f00;

        &.active {
          background: #fff;

          a {
            font-weight: bold;
            color: black;
          }
        }
      }

      &--yellow {
        background: #fa0;

        &.active {
          background: #fff;

          a {
            font-weight: bold;
            color: black;
          }
        }

        &:hover {
          background: #df8d0a;
        }
      }

      & a {
        color: #ffffff;
        text-decoration: none;
      }
    }
  }
}

.formDesplegable {
  position: relative;
  justify-self: end;

  &+& {
    margin-left: 0.2rem;
  }
}

.menu-parcial {
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  z-index: 9999;
  background-color: rgb(216, 216, 216) !important;
  margin-top: 0px;

  .nav-item {
    background: transparent !important;

    a {
      color: black !important;
      font-weight: bold !important;
    }

    &:hover {
      background: rgb(179, 179, 179) !important;
      color: white !important;
    }
  }

  &__title {
    color: black;
    padding: 0.5rem;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;

    a {
      color: black !important;
      font-weight: bold !important;
      text-align: center;
      font-size: 12px;
      text-transform: uppercase;
    }
  }
}
</style>
