<template>
  <HandleDataForms v-bind:apiSource="mongo" v-bind:dataSource="'/'+database" title="Pregrado online" collection="pregrado_online" templateTable="TableOnline" templateTableModal="TableOnlineModal" v-bind:dataExcel="dataExcel" />
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import HandleDataForms from '@/components/HandleDataForms.vue'

export default {
  components: {
    HandleDataForms,
  },
  setup() {
    const store = useStore()
    const mongo = computed(() => store.getters.getMongoURL)
    const database = computed(() => store.getters.getDBDashboard)
    const startDate = computed(() => moment().startOf('week').format('YYYY-MM-DD'))
    const endDate = computed(() => moment().format('YYYY-MM-DD'))

    let dataExcel = {
      fields: {
        createdAt: 'Fecha',
        PrimaryContactPersonFirstName: 'Nombre',
        PrimaryContactPersonLastName: 'Apellido',
        FormularioDeOrigen_c: 'Formulario de origen',
        RUToRUTProvisionalDeContacto_c: 'RUT',
        PrimaryContactEmailAddress: 'Correo',
        PrimaryPhoneCountryCode: 'Código país',
        PrimaryPhoneAreaCode: 'Código area',
        PrimaryPhoneNumber: 'Teléfono',
        Nacionalidad_c: 'Nacionalidad',
        PaisDeOrigen_c: 'País de origen',
        TipoDeCorreo_c: 'Tipo de correo',
        TipoDeTelefono_c: 'Tipo de teléfono',
        TipoDeLead_c: 'Tipo de lead',
        Modalidad_c: 'Modalidad',
        Institucion_c: 'Institución',
        Jornada_c: 'Jornada',
        Carrera_c: 'Carrera código',
        Name: 'Carrera',
        Sede_c_name: 'Sede',
        ZonaDeAdmision_c: 'Zona de admisión',
        UnidadDeNegocio_c: 'Unidad de negocio',
        preferencias: 'Preferencias',
        TipoDePrograma_c: 'Tipo de programa',
        CodigoDEMREDePrograma_c: 'Código de programa',
        ChannelType: 'Canal',
        continuidad: 'Continuidad',
        adwordTypePath: 'Adword type path',
        utm_medium: 'utm_medium',
        utm_source: 'utm_source',
        utm_campaign: 'utm_campaign',
        TipoDeOrigen_c: 'Tipo de origen',
        historial: 'Historial',
      },
      query: {
        date_field: 'createdAt', 
        collection: 'pregrado_online',
        fecha_inicio: startDate.value + ' 00:00:00',
        fecha_fin: endDate.value + ' 23:59:59',
      },
    }

    return {
      mongo,
      dataExcel,
      database
    }
  },
}
</script>