<template>
  <main>
    <section class="horizon horizon--fullheight">
      <div class="container">
        <div class="gr-4 gr-6@book gr-12@tablet gr-centered no-gutter">
          <h1 class="horizon__title font-centered">
            Iniciar sesión
          </h1>
          <div class="form-parent form-parent--login">
            <small v-if="error">Error al iniciar sesion</small>
            <form
              action=""
              class="row"
              @submit.prevent="submit"
            >
              <div class="form-control gr-12">
                <label
                  for="userEmail"
                  class="form-control__label"
                >Usuario</label>
                <input
                  v-model="form.email"
                  type="text"
                  class="form-control__field"
                  name="username"
                  placeholder="Escribe tu nombre"
                >
              </div>
              <div class="form-control gr-12">
                <label
                  for="userPassword"
                  class="form-control__label"
                >Contraseña</label>
                <input
                  v-model="form.password"
                  type="password"
                  class="form-control__field"
                  name="password"
                  placeholder="Escribe tu contraseña"
                >
              </div>
              <div class="form-control gr-12 flex-middle">
                <button class="button button--secondary button--wide">
                  Iniciar sesión
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
  /* eslint-disable no-unused-vars */
  import { auth } from '../services/firebase'
  import '@/assets/sass/style.scss'
  import { ref } from 'vue'
  import { useRouter } from 'vue-router'

  export default {
    setup() {
      const router = useRouter()
      // States
      const error = ref(null)
      const form = ref({
        email: '',
        password: '',
      })

      //Methods
      const submit = () => {
        auth
          .signInWithEmailAndPassword(form.value.email, form.value.password)
          .then(_ => {
            router.replace({ name: 'Formularios' })
          })
          .catch(err => {
            error.value = err.message
            console.error(error.value)
          })
      }

      return {
        form,
        error,
        submit,
      }
    },
  }
</script>
