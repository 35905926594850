<template>
  <HandleDataForms v-bind:apiSource="mongo" v-bind:dataSource="'/'+database" title="Formulario Retiros" collection="formulario_retiros" templateTable="TableRetirosForm" templateTableModal="TableRetirosModal" v-bind:dataExcel="dataExcel" />
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import HandleDataForms from '@/components/HandleDataForms.vue'

export default {
  components: {
    HandleDataForms,
  },
  setup() {
    const store = useStore()
    const mongo = computed(() => store.getters.getMongoURL)
    const database = computed(() => store.getters.getDBDashboard)
    const startDate = computed(() => moment().startOf('week').format('YYYY-MM-DD'))
    const endDate = computed(() => moment().format('YYYY-MM-DD'))

    let dataExcel = {
      fields: {
        createdAt: 'Fecha',
        TipoDeLead_c: 'Tipo de lead',
        PrimaryContactPersonFirstName: 'Nombre',
        PrimaryContactPersonLastName: 'Apellido paterno',
        ApellidoMaterno_c: 'Apellido materno',
        RUToRUTProvisionalDeContacto_c: 'Número de documento',
        PrimaryContactEmailAddress: 'E-mail',
        llave_st: 'Llave ST',
        codigo_demre: 'Código DEMRE',
        Institucion: 'Institución',
        sede: 'Sede',
        jornada: 'Jornada',
        carrera: 'Carrera',
        tipo: 'Tipo',
        Mensaje: 'Mensaje',
        PrimaryPhoneCountryCode: 'Código celular',
        PrimaryPhoneAreaCode: 'Código teléfono',
        PrimaryPhoneNumber: 'Número celular',
        device: 'Dispositivo',
      },
      query: {
        collection: 'formulario_retiros',
        date_field: 'createdAt',
        fecha_inicio: startDate.value + ' 00:00:00',
        fecha_fin: endDate.value + ' 23:59:59',
      },
    }

    return {
      mongo,
      dataExcel,
      database
    }
  },
}
</script>
